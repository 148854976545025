import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { bt709Luminance as lumFunc } from "lib/color-functions"
import Layout from "components/Layout"
import PhotoGrid from "../components/PhotoGrid"

const luminance = sanityPhoto =>
  lumFunc(sanityPhoto.image.asset.metadata.palette.sortPalette.background)

const Page = ({ data }) => {
  const photosForDisplay = [...data.page.photos]
  photosForDisplay.sort((a, b) => {
    const lumA = luminance(a)
    const lumB = luminance(b)

    return lumA > lumB ? 1 : lumA === lumB ? 0 : -1
  })

  return (
    <Layout>
      <PhotoGrid photos={photosForDisplay} />
    </Layout>
  )
}

export default Page

Page.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      photos: PropTypes.arrayOf(
        PropTypes.shape({
          caption: PropTypes.string,
          nsfw: PropTypes.bool,
          image: PropTypes.shape({ asset: PropTypes.object.isRequired })
            .isRequired,
          subjects: PropTypes.arrayOf(
            PropTypes.shape({ alias: PropTypes.string.isRequired }).isRequired
          ),
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  {
    page: allSanityPhoto {
      photos: nodes {
        _id
        caption
        image {
          ...Image
          ...ImagePresentationData
          asset {
            metadata {
              palette {
                sortPalette: lightMuted {
                  foreground
                  background
                  population
                }
              }
            }
          }
        }
        nsfw
        subjects {
          alias
          _id
        }
        author {
          name
        }
      }
    }
  }
`
